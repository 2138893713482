import { reactive, ref } from "vue";
import { useRouter } from 'vue-router';
import { login } from '@/api/index';
import { Notify, Toast } from 'vant';
import { inputBlur } from '@/assets/js/compontent.js';
export default {
  name: 'Brand',
  components: {
    [Notify.name]: Notify
  },

  setup() {
    // const store = useStore()
    const state = reactive({
      dealer_name: '',
      account: '',
      password: ''
    });
    const router = useRouter();
    const show = ref(false);
    const inputBlurs = inputBlur();
    const name = ref(router.currentRoute.value.query.name || localStorage.getItem('name'));
    const brand_img = ref(router.currentRoute.value.query.brand_img || localStorage.getItem('brand_img'));

    const onSubmit = async () => {
      localStorage.setItem('name', name.value);
      localStorage.setItem('brand_img', brand_img.value);
      state.brand_appid = router.currentRoute.value.query.brandAppid || localStorage.getItem('api_token');
      const data = await login(state);
      show.value = true;
      setTimeout(() => {
        show.value = false;
      }, 2000);

      if (data.error == 0) {
        Toast.success('登录成功');
        localStorage.setItem('api_token', data.data.token);
        localStorage.setItem('Appid', state.brand_appid);
        router.push({
          path: '/store'
        });
      } else {
        Notify({
          message: data.message,
          color: '#fff',
          position: 'top',
          background: '#ee0a24'
        });
      }
    };

    const onClickLeft = () => {
      router.replace({
        path: '/brand'
      });
    };

    return {
      state,
      onSubmit,
      show,
      onClickLeft,
      name,
      brand_img,
      inputBlurs
    };
  }

};