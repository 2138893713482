export function cutPictureUrl(url){
  var s=url.indexOf('com/')
  var e=url.indexOf('?')
  if (s==-1&&e==-1) {
    return url
  } else if (s>-1&&e>-1) {
    s = s+4
    return url.substring(s,e)
  }else if(e==-1){
    s = s+4
    return url.substring(s)
  }  
}
export function inputBlur(){
  let u = navigator.userAgent
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (isIOS) {
      setTimeout(() => {
          const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
          window.scrollTo(0, Math.max(scrollHeight - 1, 0))
      }, 200)
  }
}